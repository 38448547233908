import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import {Layout} from "../components/Layout"
import Button from '../components/buttons/button'
import ProductBanner from "../components/product_banners/product_banners";
import "./inversiones.css";
import Banner from "../imgs/bg_inversiones.jpg";
import { SalWrapper } from "react-sal"

const InversionesPage = () => {
    const pageTitle = 'Invertí en todos los activos del Mercado Financiero | Balanz';
    const pageDesc = 'Potenciá tus rendimientos. Tenemos disponibles todos los instrumentos del mercado y la mejor plataforma de inversiones para que tus ahorros rindan más.';

  let text = `Creemos que existen tantas inversiones como inversores.<br/> <span class='highlightsbold'>Conocé todas las alternativas de inversión que tenemos para vos en Balanz.</span>`;
  const page = (
      <>
      <ProductBanner
          bannerContainerClassName="bannerInversiones"
          bannerTrasparecy="bannerMask"
          productTitle="Inversiones"
          bannerImage={Banner}
          bannerImageClassName="cedearsBannerImage"
          textContainerClassName="textContainer"
          bannerText={text}
          bannerTextClassName="bannerTextInversiones"
          bannerImageMobile={Banner}
          mobile={false}
      />
      <SalWrapper>
      <section className="fullwidth inversiones-btns pb-0">
              {/* <Container>
                  <Row className="justify-content-center py-3 py-sm-2 py-md-3 py-md-4 py-lg-5">
                      <Col xs={12} className="d-flex">
                          <div className="text-center d-flex flex-row flex-wrap">
                              <Link className="btna" to="/inversiones/acciones"><Button variant="white secondary" text="Acciones"/></Link>
                              <Link className="btna" to="/inversiones/cedears/cedear-de-etf"><Button variant="white secondary" text="CEDEARs de ETF"/></Link>
                              <Link className="btna" to="/inversiones/fondos"><Button variant="white secondary" text="Fondos"/></Link>
                              <Link className="btna" to="/inversiones/opciones"><Button variant="white secondary" text="Opciones"/></Link>
                              <Link className="btna" to="/inversiones/bonos"><Button variant="white secondary" text="Bonos"/></Link>
                              <Link className="btna" to="/inversiones/dolares"><Button variant="white secondary" text="Dólares"/></Link>
                              <Link className="btna" to="/inversiones/letras"><Button variant="white secondary" text="Letras"/></Link>
                              <Link className="btna" to="/inversiones/cedears/packs-de-cedears"><Button variant="white secondary" text="Packs de CEDEARs"/></Link>
                              <Link className="btna" to="/inversiones/cauciones"><Button variant="white secondary" text="Cauciones"/></Link>
                              <Link className="btna" to="/inversiones/dolar-mep"><Button variant="white secondary" text="Dólar MEP"/></Link>
                              <Link className="btna" to="/inversiones/futuros"><Button variant="white secondary" text="Futuros"/></Link>
                              <Link className="btna" to="/inversiones/cedears"><Button variant="white secondary" text="CEDEARS"/></Link>
                              <Link className="btna" to="/inversiones/echeqs"><Button variant="white secondary" text="Echeqs"/></Link>
                              <Link className="btna" to="/inversiones/obligaciones-negociables"><Button variant="white secondary" text="ONs"/></Link>
                          </div>
                      </Col>
                  </Row>
              </Container> */}
                  <Container>
                      <Row className="justify-content-center py-3 py-sm-2 py-md-3 py-md-4 py-lg-5">
                        <div className="grillas-links text-center d-flex flex-row flex-wrap">
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/acciones"><Button variant="white secondary" text="Acciones"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/cedears/cedear-de-etf"><Button variant="white secondary" text="CEDEARs de ETF"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/fondos"><Button variant="white secondary" text="Fondos"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/obligaciones-negociables"><Button variant="white secondary" text="ONs"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/bonos"><Button variant="white secondary" text="Bonos"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/dolares"><Button variant="white secondary" text="Dólares"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/futuros"><Button variant="white secondary" text="Futuros"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/opciones"><Button variant="white secondary" text="Opciones"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/cauciones"><Button variant="white secondary" text="Cauciones"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/dolar-mep"><Button variant="white secondary" text="Dólar MEP"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/letras"><Button variant="white secondary" text="Letras"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/cedears/packs-de-cedears"><Button variant="white secondary" text="Packs de CEDEARs"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/cedears"><Button variant="white secondary" text="CEDEARS"/></Link>
                            </Col>
                            <Col xs={12} sm={6} md={3} lg={3}>
                                <Link className="btna" to="/inversiones/echeqs"><Button variant="white secondary" text="Echeqs"/></Link>
                            </Col>
                          
                        </div>
                      </Row>
                    </Container>
          <div className="lightgrey pb-4 pb-sm-5">
              <Container>
                  <Row className="text-center mt-3 mt-md-4 d-flex justify-content-center"
                       data-sal="fade"
                       data-sal-delay="0"
                       data-sal-duration="200"
                  >
                      <Col xs={11}>
                          <h3 className="opcion highlightbold my-3">Accedé a todos los instrumentos desde nuestra Web o App y viví 
                          <br className="d-none d-md-inline-block" /> la experiencia de invertir.</h3>
                      </Col>
                  </Row>
              </Container>
          </div>
      </section>
      <section className="fullwidthbg inversor"
               data-sal="fade"
               data-sal-delay="0"
               data-sal-duration="100"
      >
            <Container className="d-flex align-items-stretch">
                <Row className="d-flex justify-content-start align-items-center flex-grow-1 flex-shrink-1">
                    <Col xs={12} md={12}>
                        <div className="section-content">
                        <h2 className="titulo"
                                data-sal="slide-up"
                                data-sal-delay="500"
                                data-sal-duration="200"
                                data-sal-easing="ease"
                            >
                                <span className="highlightsbold">Empezá a invertir hoy</span></h2>
                            <h3 className="titulo"
                                data-sal="slide-up"
                                data-sal-delay="500"
                                data-sal-duration="200"
                                data-sal-easing="ease"
                            ><span className="tit-60">Abrí tu cuenta de inversión en Balanz, sin costo de apertura ni de mantenimiento.</span></h3>
                            <div className="cta my-5"
                                 data-sal="slide-up"
                                 data-sal-delay="650"
                                 data-sal-duration="200"
                                 data-sal-easing="ease"
                            >
                                <Link to="https://balanz.com/abrir-cuenta-2.aspx?bam_campaign=1387">
                                    <Button variant="secondary" text="Abrir cuenta"/>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
      </section>
      <section className="fullwidth asesor">
          <Container>
              <Row className="d-flex justify-content-center g-0">
              <Col xs={11}>

              <h4 className="contacta"
                  data-sal="fade"
                  data-sal-delay="700"
                  data-sal-duration="500"
                  data-sal-easing="ease"
              >El mejor asesoramiento del mercado. <br className="d-none d-md-inline-block" /><span style={{fontWeight: "300"}}>En Balanz más de 1.000.000 personas y empresas potencian su <br className="d-none d-md-inline-block" />dinero.</span></h4>
              </Col>
              </Row>
          </Container>
      </section>
        </SalWrapper>
    </>
  );

  return (
      <main>
          <Layout title={pageTitle} description={pageDesc} childrem={page} category={"inversiones"}></Layout>
      </main>
  );
};

// Step 3: Export your component
export default InversionesPage;
